export const AUTH_API_HOST = process.env.VUE_APP_AUTH_API_HOST;

export const AUTH_LOGIN = `${AUTH_API_HOST}/auth/login`;
export const AUTH_LOGOUT = `${AUTH_API_HOST}/auth/logout`;
export const AUTH_REFRESH_TOKEN = `${AUTH_API_HOST}/auth/refresh-token`;
export const AUTH_GET_PROFILE = `${AUTH_API_HOST}/auth/getProfile`;
export const AUTH_HEALTH_CHECK = `${AUTH_API_HOST}/auth/health-check`;
export const AUTH_FORGOT_PASSWORD = process.env.VUE_APP_FORGOT_PASSWORD;

export const MANPOWER_API_HOST = process.env.VUE_APP_MANPOWER_API_HOST;

export const LIST_USERS = `${MANPOWER_API_HOST}/manpower/users`;
export const LIST_USERS_FOR_ADMIN = `${MANPOWER_API_HOST}/manpower/admin/users`;
export const LIST_USER_STATION_QUEUES = `${MANPOWER_API_HOST}/manpower/user-station-queues`;
export const ADD_STATION_QUEUE = `${MANPOWER_API_HOST}/manpower/add-user-station-queue`;
export const ADD_STATION_DETAIL = `${MANPOWER_API_HOST}/manpower/user-station-queue-detail`;
export const ADD_STATION_EDIT = `${MANPOWER_API_HOST}/manpower/edit-user-station-queue`;
export const LIST_ROLES = `${MANPOWER_API_HOST}/manpower/list-roles`;
export const GET_USER = `${MANPOWER_API_HOST}/manpower/user-details`;
export const ADD_USER = `${MANPOWER_API_HOST}/manpower/add-user`;
export const EDIT_USER = `${MANPOWER_API_HOST}/manpower/edit-user`;
export const CHANGE_USER_ROLES = `${MANPOWER_API_HOST}/manpower/change-user-roles`;
export const FETCH_USERS = `${MANPOWER_API_HOST}/manpower/fetch-users`; // for adding new user, etc.
export const ACTIVATE_USER = `${MANPOWER_API_HOST}/manpower/activate-user`;
export const DEACTIVATE_USER = `${MANPOWER_API_HOST}/manpower/deactivate-user`;
export const CHANGE_USER_RESIGN_DATE = `${MANPOWER_API_HOST}/manpower/update-resign-date`;

export const LIST_USER_MGNT_CONFIG = `${MANPOWER_API_HOST}/manpower/user-management-config`;
export const EDIT_NO_LOGIN_ATTEMPT = `${MANPOWER_API_HOST}/manpower/edit-login-attempt-no`;
export const EDIT_DURATION_LOGOUT = `${MANPOWER_API_HOST}/manpower/edit-logout-duration`;
export const EDIT_DURATION_SUSPENSE = `${MANPOWER_API_HOST}/manpower/edit-suspense-duration`;
export const LIST_USER_ACCESS_LOGS = `${MANPOWER_API_HOST}/manpower/log-access`;
export const LIST_USER_MGNT_LOGS = `${MANPOWER_API_HOST}/manpower/log-account-status`;

export const GROUP_LIST = `${MANPOWER_API_HOST}/manpower/list-groups`;
export const ZONE_LIST = `${MANPOWER_API_HOST}/manpower/list-zones`;
export const LINE_LIST = `${MANPOWER_API_HOST}/manpower/list-lines`;
export const STATION_LIST = `${MANPOWER_API_HOST}/manpower/list-stations`;

export const ROSTER_LIST = `${MANPOWER_API_HOST}/manpower/list-rosters`;
export const ROSTER_DETAILS = `${MANPOWER_API_HOST}/manpower/roster-details`;
export const GET_LINE_DWS = `${MANPOWER_API_HOST}/manpower/line-dws`;
export const OT_LIST = `${MANPOWER_API_HOST}/manpower/list-ot-volunteers`;
export const OT_CREATE = `${MANPOWER_API_HOST}/manpower/ot-volunteer`;
export const OT_REMOVE = `${MANPOWER_API_HOST}/manpower/remove-ot-volunteer`;
export const MUTUAL_EXCHANGE = `${MANPOWER_API_HOST}/manpower/mutual-exchange`;
export const MARK_AVAIL = `${MANPOWER_API_HOST}/manpower/availability`;
export const REMOVE_AVAIL_CHANGE_REQUEST = `${MANPOWER_API_HOST}/manpower/remove-availability-change`;
export const LIST_AVAIL_CHANGES = `${MANPOWER_API_HOST}/manpower/list-availability-changes`;
export const SUGGEST_REPLACEMENTS = `${MANPOWER_API_HOST}/manpower/suggestions`;
export const BLOCK_LEAVE = `${MANPOWER_API_HOST}/manpower/blocked-leave`;
export const REMOVE_BLOCK_LEAVE = `${MANPOWER_API_HOST}/manpower/remove-blocked-leave`;
export const OT_ASSIGNMENT = `${MANPOWER_API_HOST}/manpower/ot-assignment`;
export const ASSIGN_OT = `${MANPOWER_API_HOST}/manpower/ot-assignment`;
export const ONEFORTWOHALF_TO = `${MANPOWER_API_HOST}/manpower/ot-assignment-onefortwohalf`;
export const ASSIGN_SPARE = `${MANPOWER_API_HOST}/manpower/spare-assignment`;
export const ASSIGN_EXTEND_OT = `${MANPOWER_API_HOST}/manpower/shift-extended-ot`;
export const ROSTER_GAP = `${MANPOWER_API_HOST}/manpower/roster-gap`;
export const EDIT_ROSTER = `${MANPOWER_API_HOST}/manpower/edit-roster`;
export const GET_CURRENT_ROSTER = `${MANPOWER_API_HOST}/manpower/current-roster`;
export const POST_PLANNED_OT = `${MANPOWER_API_HOST}/manpower/planned-ot`;
export const MARK_OT_UNAVAIL = `${MANPOWER_API_HOST}/manpower/ot-unavailability`;
export const REMOVE_OT_UNAVAIL = `${MANPOWER_API_HOST}/manpower/remove-ot-unavailability`;
export const EXTEND_VACANT = `${MANPOWER_API_HOST}/manpower/extension-vacants`;
export const LIST_CONFLICTS = `${MANPOWER_API_HOST}/manpower/list-conflicts`;
export const LIST_OTHOURS = `${MANPOWER_API_HOST}/manpower/ot-hours`;
export const LIST_MOMREGULATIONS = `${MANPOWER_API_HOST}/manpower/mom-regulations`;
export const LIST_LEAVES = `${MANPOWER_API_HOST}/manpower/leaves`;
export const LIST_PENDINGREPLACEMENTS = `${MANPOWER_API_HOST}/manpower/pending-replacements`;
export const COVERING_SHIFT_SAME_STATION = `${MANPOWER_API_HOST}/manpower/dual-shift-cover`;
export const COVERING_SHIFT = `${MANPOWER_API_HOST}/manpower/dual-shift`;
export const CANCEL_COVERING_SHIFT = `${MANPOWER_API_HOST}/manpower/cancel-dual-shift`;
export const CANCEL_AVAILABILITY = `${MANPOWER_API_HOST}/manpower/cancel-availability-change`;
export const MANPOWER_HEALTH_CHECK = `${MANPOWER_API_HOST}/manpower/health-check`;
export const GET_NEXT_ROSTER = `${MANPOWER_API_HOST}/manpower/next-rosters`;

/** FOR TESTING */
export const CLEAR_ROSTER_DATA = `${MANPOWER_API_HOST}/manpower/test/clear-data`;
export const GENERATE_ROSTERS = `${MANPOWER_API_HOST}/manpower/test/generate-rosters`;
export const ADD_ROSTER_CYCLE = `${MANPOWER_API_HOST}/manpower/test/roster-cycle`;
/** END FOR TESTING */

export const FILE_SERVICE_API_HOST = process.env.VUE_APP_FILE_SERVICE_API_HOST;
export const DOWNLOAD_FILE = `${FILE_SERVICE_API_HOST}/download`;
export const UPLOAD_FILE = `${FILE_SERVICE_API_HOST}/upload`;
export const FILESERVICE_HEALTH_CHECK = `${FILE_SERVICE_API_HOST}/fs/health-check`;

/** FOR TESTING */
export const UPLOAD_SAP_ROSTER = `${FILE_SERVICE_API_HOST}/sap-roster-upload`;
export const UPLOAD_SAP_LEAVE = `${FILE_SERVICE_API_HOST}/sap-data-leaves-upload`;
export const UPLOAD_SAP_OT = `${FILE_SERVICE_API_HOST}/sap-data-ot-upload`;
export const UPLOAD_SAP_RESIGNEE = `${FILE_SERVICE_API_HOST}/sap-data-resignee-upload`;
export const GET_SAP_SYNC_ERRORS = `${MANPOWER_API_HOST}/manpower/sap-error-report`;
export const GET_SAP_SYNC_ERRORS_BY_FILE = `${MANPOWER_API_HOST}/manpower/sap-error-report-by-file`;
export const GET_SAP_FILES = `${FILE_SERVICE_API_HOST}/sap-data-list`;
/** END FOR TESTING */

//CASH MANGEMENT
export const CASHMANAGEMENT_API_HOST =
  process.env.VUE_APP_CASHMANAGEMENT_API_HOST;

export const STATION_CASH_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/station-summary`;
export const GET_STATION_CASH_ACCOUNTS = `${CASHMANAGEMENT_API_HOST}/cm/station-accounts`;

export const PSC_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/psc/summary`;

export const SHIFT_END_LIST = `${CASHMANAGEMENT_API_HOST}/cm/shift-end-list`;
export const GET_TICKET_EVENTS_FOR_STATION = `${CASHMANAGEMENT_API_HOST}/cm/events`;
export const CREATE_EVENT = `${CASHMANAGEMENT_API_HOST}/cm/create-event`;
export const CLOSE_EVENT = `${CASHMANAGEMENT_API_HOST}/cm/close-event`;
export const GET_TICKET_LOGS = `${CASHMANAGEMENT_API_HOST}/cm/ticket-logs`;
export const GET_TICKET_LOG_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/ticket-log`;
export const CREATE_TICKET_LOG = `${CASHMANAGEMENT_API_HOST}/cm/create-ticket-log`;
export const EDIT_TICKET_LOG = `${CASHMANAGEMENT_API_HOST}/cm/edit-ticket-log`;
export const CREATE_PSM_LOG = `${CASHMANAGEMENT_API_HOST}/cm/create-psm-log`;
export const EDIT_PSM_LOG = `${CASHMANAGEMENT_API_HOST}/cm/edit-psm-log`;
export const GET_PSM_LOGS = `${CASHMANAGEMENT_API_HOST}/cm/psm-logs`;
export const GET_PSM_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/psm-log`;
export const GET_PSM_TRANSACTION_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/psm-transaction-details`;
export const OPEN_CERTIS_BAG = `${CASHMANAGEMENT_API_HOST}/cm/open-certis-bag`;
export const DISCARD_CERTIS_BAG = `${CASHMANAGEMENT_API_HOST}/cm/discard-certis-bag`;
export const GET_CERTIS_BAG_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/certis-bag`;
export const GET_CERTIS_BAG_TRANSACTIONS = `${CASHMANAGEMENT_API_HOST}/cm/certis-bag-logs`;
export const CREATE_CERTIS_BAG = `${CASHMANAGEMENT_API_HOST}/cm/test/create-certis-bag`;
export const MOVE_CERTIS_BAGS_PSC = `${CASHMANAGEMENT_API_HOST}/cm/move-certis-bags-psc`;
export const EDIT_CERTIS_BAG_TRANSACTION = `${CASHMANAGEMENT_API_HOST}/cm/edit-certis-bag-log`;
export const GET_PSC_CASH_FLOAT_MAIN_PAGE_DATA = `${CASHMANAGEMENT_API_HOST}/cm/cash-float-summary`;
export const GET_PRE_ENCODED_MAIN_PAGE_DATA = `${CASHMANAGEMENT_API_HOST}/cm/pre-encoded-summary`;
export const GET_PSM_MAIN_PAGE_DATA = `${CASHMANAGEMENT_API_HOST}/cm/psm-summary`;
export const MOVE_TE_BAGS_FROM_PSC = `${CASHMANAGEMENT_API_HOST}/cm/cdf/move-te-bags`;
export const LIST_BAG_STATUS = `${CASHMANAGEMENT_API_HOST}/cm/list-bag-status`;
// export const EDIT_CERTIS_BAG_LOG = `${CASHMANAGEMENT_API_HOST}/cm/edit-certis-bag-log`;
// export const LIST_SHIFT_END_REPORTS = `${CASHMANAGEMENT_API_HOST}/cm/shift-ends`;
// export const GET_SHIFT_END_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/shift-end`;
// export const CREATE_SHIFT_END_REPORTS = `${CASHMANAGEMENT_API_HOST}/cm/create-shift-end`;
// export const SIGN_SHIFT_END = `${CASHMANAGEMENT_API_HOST}/cm/sign-shift-end`;

export const AFC_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/afc/afc-summary`;

export const COIN_FLOAT_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/afc/coin-float-summary`;
export const USE_COIN_TUBE = `${CASHMANAGEMENT_API_HOST}/cm/afc/use-coin-tube`;

export const COIN_FLUSH_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/afc/coin-flush-summary`;
export const LIST_COIN_FLUSH = `${CASHMANAGEMENT_API_HOST}/cm/afc/coin-flust-list`;
export const CREATE_COIN_FLUSH = `${CASHMANAGEMENT_API_HOST}/cm/afc/create-coin-flush`;
export const VOID_COIN_FLUSH = `${CASHMANAGEMENT_API_HOST}/cm/afc/void-coin-flush`;

export const CASH_BOX_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/afc/cash-box-summary`;
export const GET_CASH_BOX_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/afc/cash-box`;
export const CREATE_CASH_BOX = `${CASHMANAGEMENT_API_HOST}/cm/afc/create-cash-box`;
export const DELETE_CASH_BOX = `${CASHMANAGEMENT_API_HOST}/cm/afc/delete-cash-box`;
export const MOVE_CASH_BOX = `${CASHMANAGEMENT_API_HOST}/cm/afc/move-cash-box`;

export const AFC_CASH_BAG_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/afc/cash-bag-summary`;
export const AFC_CASH_TRANSACTION_LIST = `${CASHMANAGEMENT_API_HOST}/cm/afc/cash-bag-transaction-list`;
export const MOVE_CASH_BAGS_AFC = `${CASHMANAGEMENT_API_HOST}/cm/afc/move-cash-bag`;

export const LIST_STATION_CASH_COLLECTION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/list-sccf`;
export const GET_STATION_CASH_COLLECTION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/sccf`;
export const CREATE_STATION_CASH_COLLECTION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/create-sccf`;
export const SAVE_AS_DRAFT_SCCF = `${CASHMANAGEMENT_API_HOST}/cm/save-as-draft-sccf`;
export const DELETE_DRAFT_SCCF = `${CASHMANAGEMENT_API_HOST}/cm/sccf/`;
export const SET_DRAFT_SCCF = `${CASHMANAGEMENT_API_HOST}/cm/sccf`;

export const LIST_STATION_TRANSFERS = `${CASHMANAGEMENT_API_HOST}/cm/list-station-transfer`;
export const GET_STATION_TRANSFER = `${CASHMANAGEMENT_API_HOST}/cm/station-transfer`;
export const CREATE_STATION_TRANSFER = `${CASHMANAGEMENT_API_HOST}/cm/create-station-transfer`;

export const GET_CASH_ORDER_LIST = `${CASHMANAGEMENT_API_HOST}/cm/cash-orders`;
export const GET_ADMIN_CASH_ORDER_LIST = `${CASHMANAGEMENT_API_HOST}/cm/admin/cash-orders`;
export const GET_CASH_ORDER_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/cash-order`;
export const CREATE_CASH_ORDER = `${CASHMANAGEMENT_API_HOST}/cm/create-cash-order`;
export const APPROVE_CASH_ORDER = `${CASHMANAGEMENT_API_HOST}/cm/approve-cash-order`;
export const REJECT_CASH_ORDER = `${CASHMANAGEMENT_API_HOST}/cm/reject-cash-order`;
export const GET_CASH_ORDER_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/report/receipt-report`;

export const CREATE_SHIFT_END_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/create-shift-end`;
export const GET_PSC_SHIFT_INFO = `${CASHMANAGEMENT_API_HOST}/cm/psc-shift-info`;
export const GET_SHIFT_END_INFO = `${CASHMANAGEMENT_API_HOST}/cm/shift-end-info`;
export const CREATE_CASH_DECLARATION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/create-cdf`;
export const DELETE_CASH_DECLARATION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/delete-cdf`;
export const GET_CASH_DECLARATION_FORM_LIST = `${CASHMANAGEMENT_API_HOST}/cm/cdf-list`;
export const MONTHEND_DECLAR_ATIONS = `${CASHMANAGEMENT_API_HOST}/cm/monthenddeclarations`;
export const CREATE_MONTHEND_DECLAR_ATIONS = `${CASHMANAGEMENT_API_HOST}/cm/create-monthenddeclaration`;
export const MONTH_END_DECLAR_ATION = `${CASHMANAGEMENT_API_HOST}/cm/monthenddeclaration`;
export const GET_CASH_DECLARATION_FORM_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/cdf-details`;
export const SIGN_CASH_DECLARATION_FORM = `${CASHMANAGEMENT_API_HOST}/cm/sign-cdf`;
export const CREATE_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/create-discrepancy-report`;
export const MY_DISCREPANCY_REPORTS = `${CASHMANAGEMENT_API_HOST}/cm/my-discrepancy-report`;
export const DISCREPANCY_REPORT_LIST = `${CASHMANAGEMENT_API_HOST}/cm/discrepancy-report-list`;
export const DISCREPANCY_REPORT_DETAILS = `${CASHMANAGEMENT_API_HOST}/cm/discrepancy-report-details`;
export const REJECT_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/reject-discrepancy-report`;
export const CLOSE_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/close-discrepancy-report`;
export const ASSIGN_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/assign-discrepancy-report`;
export const APPROVE_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/approve-discrepancy-report`;
export const EDIT_DISCREPANCY_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/edit-discrepancy-report`;
export const STATION_TRANSACTION_LIST = `${CASHMANAGEMENT_API_HOST}/cm/offline-transaction-logs`;

export const GET_CASH_MANAGEMENT_CONFIG = `${CASHMANAGEMENT_API_HOST}/cm/config`;
export const UPDATE_COINTUBE_CONFIG = `${CASHMANAGEMENT_API_HOST}/cm/config/update-cointube`;
export const UPDATE_CUTOFF_TIME = `${CASHMANAGEMENT_API_HOST}/cm/config/update-cutoff-time`;
export const UPDATE_STATION_CASH_THRESHOLD = `${CASHMANAGEMENT_API_HOST}/cm/config/update-station-cash-thresholds`;
export const CASHMANAGEMENT_HEALTH_CHECK = `${CASHMANAGEMENT_API_HOST}/cm/health-check`;

// STATION COLLECTION SUMMARY
export const GET_COLLECTION_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/summary`;
export const GET_COLLECTION_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/report`;
export const VALIDATE_CERTIS_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/validate-certis-report`;
export const VALIDATE_SEP_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/validate-sep-report`;
export const UPLOAD_CERTIS_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/create-certis-report`;
export const UPLOAD_SEP_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/upload-sep-report`;
export const GET_CERTIS_REPORT_ACTIVITY_LOG = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/collection-report-list`;
export const GET_SCCF_CASH_OUT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/sccf-vs-cash-out`
export const PSM_VS_SEP_VS_SCCF = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/psm-vs-sep-vs-sccf`
export const GET_SCCF_CASH_IN = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/sccf-vs-cash-in`
export const CISCO_VS_COVER = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/cisco-vs-cover`
export const CM_VALIDATE_SEP_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/cm-validate-sep-report`
export const CREATE_SEP_REPORT = `${CASHMANAGEMENT_API_HOST}/cm/collection-summary/create-sep-report`
export const MONTH_END_DECLAR_ATION_SUMMARY = `${CASHMANAGEMENT_API_HOST}/cm/monthenddeclaration-summary`;

// NOTIFICATION
export const NOTIFICATION_API_HOST = process.env.VUE_APP_NOTIFICATION_API_HOST;
export const GET_NOTIFICATIONS = `${NOTIFICATION_API_HOST}/notification/list-notifications`;
export const MARK_READ_NOTIFICATION = `${NOTIFICATION_API_HOST}/notification/mark-read-all`;
export const LIST_EMAIL_TEMPLATE = `${NOTIFICATION_API_HOST}/notification/list-email-template`;
export const EMAIL_TEMPLATE_DETAILS = `${NOTIFICATION_API_HOST}/notification/template-details`;
export const EDIT_EMAIL_TEMPLATE = `${NOTIFICATION_API_HOST}/notification/edit-email-template`;
export const NOTIFICATION_HEALTH_CHECK = `${NOTIFICATION_API_HOST}/notification/health-check`;

// TOURIST
export const TOURIST_API_HOST = process.env.VUE_APP_TOURIST_API_HOST;
export const TOURIST_GET_LANDMARKS = `${TOURIST_API_HOST}/tourist/read-map`;
export const TOURIST_MAP_SEARCH = `${TOURIST_API_HOST}/tourist/list-map`;
export const TOURIST_MAP_DETAILS = `${TOURIST_API_HOST}/tourist/map-details`;
export const TOURIST_CREATE_MAP = `${TOURIST_API_HOST}/tourist/create-map`;
export const TOURIST_HEALTH_CHECK = `${TOURIST_API_HOST}/tourist/health-check`;

//INVENTORY
export const INVENTORY_API_HOST = process.env.VUE_APP_INVENTORY_API_HOST;

export const CREATE_INVENTORY_LOCATION = `${INVENTORY_API_HOST}/in/create-inventory-location`;
export const EDIT_INVENTORY_LOCATION = `${INVENTORY_API_HOST}/in/edit-inventory-location`;
export const DELETE_INVENTORY_LOCATION = `${INVENTORY_API_HOST}/in/delete-inventory-location`;
export const GET_INVENTORY_LOCATION_DETAILS = `${INVENTORY_API_HOST}/in/inventory-item-location`;
export const GET_INVENTORY_LOCATION_LIST = `${INVENTORY_API_HOST}/in/inventory-location-list`;

export const CREATE_INVENTORY_TYPE = `${INVENTORY_API_HOST}/in/create-inventory-type`;
export const EDIT_INVENTORY_TYPE = `${INVENTORY_API_HOST}/in/edit-inventory-type`;
export const DELETE_INVENTORY_TYPE = `${INVENTORY_API_HOST}/in/delete-inventory-type`;
export const GET_INVENTORY_TYPE_DETAILS = `${INVENTORY_API_HOST}/in/inventory-type-details`;
export const GET_INVENTORY_TYPE_LIST = `${INVENTORY_API_HOST}/in/inventory-type-list`;

export const CREATE_FIRST_AID_BOX = `${INVENTORY_API_HOST}/in/create-inventory-first-aid-box`;
export const DELETE_FIRST_AID_BOX = `${INVENTORY_API_HOST}/in/delete-first-aid-box`;
export const UPDATE_FIRST_AID_BOX = `${INVENTORY_API_HOST}/in/update-first-aid-box-details`;
export const GET_FIRST_AID_BOX_DETAILS = `${INVENTORY_API_HOST}/in/first-aid-box-details`;
export const GET_FIRST_AID_BOX_LIST = `${INVENTORY_API_HOST}/in/first-aid-box-list`;
export const VERIFY_FIRST_AID_BOX = `${INVENTORY_API_HOST}/in/verify-first-aid-boxes`;
export const CREATE_FIRST_AID_FORM = `${INVENTORY_API_HOST}/in/create-first-aid-form`;
export const FIRST_AID_FORM_LIST = `${INVENTORY_API_HOST}/in/first-aid-form-list`;
export const FIRST_AID_LOG_DETAILS = `${INVENTORY_API_HOST}/in/first-aid-log-details`;

export const GET_INVENTORY_ORDER_LIST = `${INVENTORY_API_HOST}/in/order/list`;
export const GET_INVENTORY_ORDER_DETAILS = `${INVENTORY_API_HOST}/in/order/details`;
export const GET_INVENTORY_LAST_ORDER_AMOUNT = `${INVENTORY_API_HOST}/in/order/last-order-amount`;
export const EDIT_INVENTORY_ORDER_DOD = `${INVENTORY_API_HOST}/in/order/update-delivery-date`;
export const CREATE_INVENTORY_ORDER = `${INVENTORY_API_HOST}/in/order/place-order`;
export const APPROVE_ORDER = `${INVENTORY_API_HOST}/in/order/approve-order`;
export const REJECT_ORDER = `${INVENTORY_API_HOST}/in/order/reject-order`;

export const MOVE_IN_ITEMS = `${INVENTORY_API_HOST}/in/move-in-items`;
export const MOVE_OUT_ITEMS = `${INVENTORY_API_HOST}/in/move-out-items`;
export const OPEN_SMART_CARD_BOX = `${INVENTORY_API_HOST}/in/open-smart-card-box`;
export const USE_ITEMS = `${INVENTORY_API_HOST}/in/use-items`;

export const GET_INVENTORY_MAIN_PAGES = `${INVENTORY_API_HOST}/in/inventy-type-summary`;
export const UPDATE_INVENTORY = `${INVENTORY_API_HOST}/in/update-inventory`;
export const LIST_EXPIRED_INVENTORIES = `${INVENTORY_API_HOST}/in/expired-inventory-list`;
export const DISCARD_EXPIRED_INVENTORIES = `${INVENTORY_API_HOST}/in/discard-expired-items`;

export const GET_CONFIGURATION_MAIN_PAGE_DATA = `${INVENTORY_API_HOST}/in/configuration`;
export const EDIT_DELIVERY_ELAPSE_DAYS = `${INVENTORY_API_HOST}/in/edit-delivery-elapse-days`;
export const EDIT_EXPIRE_THRESHOLD = `${INVENTORY_API_HOST}/in/edit-expire-threshold`;

export const GET_INVENTORY_TYPE_THRESHOLD_DETAILS = `${INVENTORY_API_HOST}/in/inventory-type-threshold-details`;
export const GET_INVENTORY_TYPE_THRESHOLD_LIST = `${INVENTORY_API_HOST}/in/inventory-type-threshold-list`;
export const CREATE_INVENTORY_TYPE_THRESHOLD = `${INVENTORY_API_HOST}/in/create-inventory-type-threshold`;
export const EDIT_INVENTORY_TYPE_THRESHOLD = `${INVENTORY_API_HOST}/in/edit-inventory-type-threshold`;
export const DELETE_INVENTORY_TYPE_THRESHOLD = `${INVENTORY_API_HOST}/in/delete-inventory-type-threshold`;

export const GET_INVENTORY_MAIN_PAGE = `${INVENTORY_API_HOST}/in/main-page`;

export const INVENTORY_HEALTH_CHECK = `${INVENTORY_API_HOST}/in/health-check`;

//3rd parties
export const GOOGLE_MAP = "https://www.google.com/maps";

//FAULT_MANAGEMENT
export const FAULTMANAGEMENT_API_HOST =
  process.env.VUE_APP_FAULTMANAGEMENT_API_HOST;

export const CREATE_FAULT_OCCURRENCE = `${FAULTMANAGEMENT_API_HOST}/fm/create-fault-occurrence`;
export const EDIT_FAULT_OCCURRENCE = `${FAULTMANAGEMENT_API_HOST}/fm/edit-station-fault-occurrence`;
export const FAULT_OCCURRENCE_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/fault-occurrence-details`;
export const FAULT_OCCURRENCE_TRANSACTION_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/fault-occurrence-transaction-details`;
export const FAULT_OCCURRENCE_LIST = `${FAULTMANAGEMENT_API_HOST}/fm/fault-occurrence-list`;

export const CREATE_STATION_FAULT = `${FAULTMANAGEMENT_API_HOST}/fm/create-fault`;
export const EDIT_STATION_FAULT = `${FAULTMANAGEMENT_API_HOST}/fm/edit-station-fault`;
export const STATION_FAULT_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/fault-details`;
export const STATION_FAULT_TRANSACTION_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/fault-transaction-details`;
export const STATION_FAULT_LIST = `${FAULTMANAGEMENT_API_HOST}/fm/fault-list`;
export const STATION_FAULT_LOG_LIST = `${FAULTMANAGEMENT_API_HOST}/fm/fault-log-list`;

export const CREATE_FAULT_EBOOKLET = `${FAULTMANAGEMENT_API_HOST}/fm/create-fault-e-booklet`;
export const DELETE_FAULT_EBOOKLET = `${FAULTMANAGEMENT_API_HOST}/fm/delete-fault-e-booklet`;
export const EDIT_FAULT_EBOOKLET = `${FAULTMANAGEMENT_API_HOST}/fm/edit-fault-e-booklet`;
export const FAULT_EBOOKLET_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/fault-e-booklet-details`;
export const FAULT_EBOOKLET_LIST = `${FAULTMANAGEMENT_API_HOST}/fm/list/fault-e-booklets`;
export const FAULT_MANAGEMENT_HEALTH_CHECK = `${FAULTMANAGEMENT_API_HOST}/fm/health-check`;

export const FAULT_MANAGEMENT_CONFIGURATION_DETAILS = `${FAULTMANAGEMENT_API_HOST}/fm/config`;
export const FAULT_OCCURRENCE_UPDATE_THRESHOLD_EXPIRE = `${FAULTMANAGEMENT_API_HOST}/fm/edit-expire-threshold`;

export const LIST_MOMS_BRANCH =  `${FAULTMANAGEMENT_API_HOST}/fm/moms-resource/branch-list`;
export const LIST_MOMS_GROUP = `${FAULTMANAGEMENT_API_HOST}/fm/moms-resource/functionallocationworkcentreplannergroup`;

export const CLOSE_STATION_FAULT = `${FAULTMANAGEMENT_API_HOST}/fm/close-fault`;

//SHIFT_HANDOVER
export const SHIFTHANDOVER_API_HOST =
  process.env.VUE_APP_SHIFTHANDOVER_API_HOST;

export const GET_AFC_CASHBAG_E_LOGBOOK = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/afc-cash-bag`;
export const GET_GTM_CASHBOX_E_LOGBOOK = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/gtm-cash-box`;
export const GET_GTM_COINFLOAT_E_LOGBOOK = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/gtm-coin-float`;
export const GET_MANUAL_CHANGE_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/psc-cash-bag`;
export const GET_PSC_CDF_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/psc-cdf`;
export const GET_PRE_ENCODED_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/pre-encoded`;
export const GET_PSC_CASHBAG_E_LOGBOOK = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/psc-cash-bag`;
export const GET_PSM_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/psm`;
export const GET_SCCF_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/sccf`;
export const GET_STATION_TRANSFER_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/cm/station-transfer`;

export const GET_SM_DUTY_RADIO_AND_DUTY_PHONE = `${SHIFTHANDOVER_API_HOST}/shifthandover/sh/duty-comm`;
export const GET_SM_DUTY_KEYS = `${SHIFTHANDOVER_API_HOST}/shifthandover/sh/duty-key`;
export const GET_FITNESS_CONFIRMATION = `${SHIFTHANDOVER_API_HOST}/shifthandover/sh/fitness`;
export const GET_LAST_HANDOVER_INFO = `${SHIFTHANDOVER_API_HOST}/shifthandover/get-last-handover`;

export const GET_STATION_OCCURRENCE_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/fm/occurrence`;
export const GET_STATION_OUTSTANDING_FAULT_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/fm/outstanding-fault`;

export const GET_SMARTCARD_INVENTORY_SUMMARY = `${SHIFTHANDOVER_API_HOST}/shifthandover/in/smart-card`;

export const GET_AFC_STORE_SPARE_KEY_LOGS = `${SHIFTHANDOVER_API_HOST}/shift-handover/afc-store-spare-key/logs`;
export const GET_AFC_STORE_SPARE_KEY_DETAILS = `${SHIFTHANDOVER_API_HOST}/shifthandover/afc-store-key-box`;
export const UPDATE_AFC_STORE_SPARE_KEY = `${SHIFTHANDOVER_API_HOST}/shifthandover/afc-store-spare-key/create`;

export const GET_SM_DUTY_ITEM_LOGS = `${SHIFTHANDOVER_API_HOST}/shift-handover/sm-duty-item/logs`;
export const GET_SM_DUTY_ITEM_LIST = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-duty-items/list`;
export const CREATE_SM_DUTY_ITEM = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-duty-items/create`;
export const UPDATE_SM_DUTY_ITEM = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-duty-items/update`;
export const REMOVE_SM_DUTY_ITEM = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-duty-items/remove`;

export const GET_SM_KEY_LOGS = `${SHIFTHANDOVER_API_HOST}/shift-handover/sm-key/logs`;
export const GET_SM_KEY_LIST = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-key/list`;
export const CREATE_SM_KEY = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-key/create`;
export const UPDATE_SM_KEY = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-key/update`;
export const REMOVE_SM_KEY = `${SHIFTHANDOVER_API_HOST}/shifthandover/sm-key/remove`;

export const GET_HEALTH_DECLARATION = `${SHIFTHANDOVER_API_HOST}/shifthandover/health-declaration`;
export const UPDATE_HEALTH_DECLARATION = `${SHIFTHANDOVER_API_HOST}/shifthandover/health-declaration/update`;

export const GET_SHIFT_HANDOVER_REPORT_DETAILS = `${SHIFTHANDOVER_API_HOST}/shifthandover/details`;
export const GET_SHIFT_HANDOVER_REPORTS_LIST = `${SHIFTHANDOVER_API_HOST}/shifthandover/list`;
export const CONFIRM_TAKEOVER = `${SHIFTHANDOVER_API_HOST}/shifthandover/takeover`;
export const REJECT_SHIFT_HANDOVER = `${SHIFTHANDOVER_API_HOST}/shifthandover/reject-handover`;
export const CREATE_SHIFT_HANDOVER_REPORT = `${SHIFTHANDOVER_API_HOST}/shifthandover/handover`;
export const GET_SHIFT_HANDOVER_TYPES = `${SHIFTHANDOVER_API_HOST}/shifthandover/avail-types`;

export const SHIFT_HANDOVER_HEALTH_CHECK = `${SHIFTHANDOVER_API_HOST}/shifthandover/health-check`;

//OPS_COMMS
export const OPSCOMM_API_HOST = process.env.VUE_APP_OPSCOMM_API_HOST;

export const ACKNOWLEDGEMENT_DETAILS = `${OPSCOMM_API_HOST}/oc/acknowledgement-details`;
export const LIST_PERSONAL_ACKNOWLEDGEMENT = `${OPSCOMM_API_HOST}/oc/list-personal-acknowledgement`;
export const ACKNOWLEDGEMENT_SUMMARY = `${OPSCOMM_API_HOST}/oc/acknowledgement-summary`;
export const USER_ACKNOWLEDGEMENT_SUMMARY = `${OPSCOMM_API_HOST}/oc/user-acknowledgement-summary`;
export const ACKNOWLEDGE_CP_Update = `${OPSCOMM_API_HOST}/oc/acknowledge`;

export const CP_UPDATE_DETAILS = `${OPSCOMM_API_HOST}/oc/cp-update-details`;
export const CP_UPDATE_SUMMARY = `${OPSCOMM_API_HOST}/oc/cp-update-summary`;
export const LIST_CP_UPDATES = `${OPSCOMM_API_HOST}/oc/list-cp-updates`;
export const CREATE_CP_UPDATE = `${OPSCOMM_API_HOST}/oc/create-cp-update`;
export const EDIT_CP_UPDATE = `${OPSCOMM_API_HOST}/oc/edit-cp-update`;
export const REMOVE_CP_UPDATE = `${OPSCOMM_API_HOST}/oc/remove-cp-update`;
export const OPS_COMM_HEALTH_CHECK = `${OPSCOMM_API_HOST}/oc/health-check`;

export const OPS_COMM_CONFIGURATION_DETAILS = `${OPSCOMM_API_HOST}/oc/config`;
export const OPS_COMM_UPDATE_THRESHOLD_EXPIRE = `${OPSCOMM_API_HOST}/oc/edit-expire-threshold`;

export const GET_POPULAR_LINKS = `${OPSCOMM_API_HOST}/oc/list-hyperlinks`;
export const UPDATE_POPULAR_LINKS = `${OPSCOMM_API_HOST}/oc/update-hyperlinks`;

// MOBILE
export const GENERATE_OTP = `${AUTH_API_HOST}/auth/mobile/generate-otp`;
export const MOBILE_URL_SCHEME = process.env.VUE_APP_MOBILE_URL_SCHEME;
export const MOBILE_LOGIN_SUCCESS = (otp) =>
  `${MOBILE_URL_SCHEME}login-success/${otp}`;
export const MOBILE_LOGIN_FAILURE = `${MOBILE_URL_SCHEME}login-failure`;
export const MOBILE_LOGOUT_SUCCESS = `${MOBILE_URL_SCHEME}logout-success`;
export const MOBILE_LOGOUT_FAILURE = `${MOBILE_URL_SCHEME}logout-failure`;

// ATOMS Mobile
export const ATOMS_MOBILE_API_HOST = process.env.VUE_APP_ATOMS_MOBILE_API_HOST;

export const ATOMS_LOGIN = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/Login`;
export const GET_DUTY_SCHEDULE = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/GetDutySchedule`;
export const GET_DUTY_SCHEDULES_SUMMARY = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/GetDutySchedulesSummary`;
export const GET_TRAIN_TIME_TABLE_SUMMARY = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/GetTrainTimetablesSummary`;
export const GET_TRAIN_TIME_TABLE = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/GetTrainTimetable`;
export const FETCH_ALL_PDF_INDEXES = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/FetchAllPDFIndexes`;
export const SEARCH_PDF_INDEXES = `${ATOMS_MOBILE_API_HOST}/ATOMSMobile.svc/SearchTextFromPDFIndexes`;
